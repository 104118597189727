import React from 'react'
import { truncate } from 'lodash'
import { Parallax } from '@components/layout'
import { AnimatedLink } from '@components/shared'

import {
	item,
	item__inner,
	item__title,
	item__button,
	item__date,
} from '../styles/blogitem.module.scss'

const BlogItem = ({
	featuredImage,
	slug,
	date,
	title,
	content,
	onClick,
	className = [],
}) => {
	const long = title.length > 100 ? 300 : 200
	return (
		<div className={item}>
			<AnimatedLink to={`/blog/${slug}/`}>
				<small className={item__date}>{date.substr(0,10)}</small>
				<Parallax height={long} image={featuredImage}>
					<div className={item__inner} />
					<h5
						className={item__title}
						dangerouslySetInnerHTML={{
							__html: title,
						}}
					/>
				</Parallax>
				<button className={item__button}>
					Przeczytaj
					<i className='icofont-double-right' />
				</button>
			</AnimatedLink>
		</div>
	)
}

export default BlogItem
