import React from 'react'
import PostItem from './PostItem'
import {
	posts__wrapper, 
	posts__title, 
	posts__wrapper__items
} from '../styles/posts.module.scss'

const Posts = ({ posts = [], title = 'Poszukaj czegoś dla siebie' }) => {
	return (
		<div className={posts__wrapper}>
			<h2 className={posts__title}>{title}</h2>
			<div className={posts__wrapper__items}>
			{posts.map((el, k) => <PostItem {...el} key={k} />)}
			</div>
		</div>
	)
}

export default Posts
